<template>
  <div id="layout">
    <search-view></search-view>
  </div>
</template>

<script>
import MenuBar from "@/views/site/blocks/MenuBar";
import FooterBar from "@/views/site/blocks/FooterBar";
import SearchView from "@/views/site/search/SearchView";

export default {
  name: "NewSearch",
  data() {
    return {
      route_name: null,
    };
  },
  components: {
    MenuBar,
    FooterBar,
    SearchView,
    FooterBar,
  },
  created() {},
};
</script>
<style scoped>
.header-container {
  display: block !important;
}
</style>
